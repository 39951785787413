import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Typewriter from 'typewriter-effect';

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-align: center;
  background-color: #22223b;
  color: #f2e9e4;
`;

const HeroContent = styled.div`
  max-width: 800px;

  h1 {
    font-size: 4em;
    margin-bottom: 20px;
    color: #f2e9e4;
  }

  h2 {
    font-size: 1.5em; /* Adjusted size for subtitle */
    color: #c9ada7;
    margin-top: 20px;
    font-style: italic;
  }
`;

const Home = () => {
  const [showSubTitle, setShowSubTitle] = useState(false);

  return (
    <HeroSection id="home">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <HeroContent>
          <h1>
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString("Hello, I'm Ashish Kaushik")
                  .callFunction(() => {
                    setShowSubTitle(true);
                  })
                  .start();
              }}
              options={{
                cursor: '',
                delay: 50,
              }}
            />
          </h1>
          {showSubTitle && (
            <h2>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(
                      'Versatile IT Professional specializing in Business Analysis and Software Engineering.'
                    )
                    .start();
                }}
                options={{
                  cursor: '',
                  delay: 40,
                }}
              />
            </h2>
          )}
        </HeroContent>
      </motion.div>
    </HeroSection>
  );
};

export default Home;