import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaCheckCircle } from 'react-icons/fa';

const ExperienceSection = styled.section`
  padding: 60px 20px;
  background-color: #22223b;
  color: #f2e9e4;

  h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
    color: #f2e9e4;
  }
`;

const ExperienceContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .experience-grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  .experience-item {
    display: flex;
    background-color: #4a4e69;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 6px 8px rgba(0,0,0,0.15);
    }

    h3 {
      font-size: 1.5em;
      margin-bottom: 10px;
      color: #8cabdb;
    }

    p {
      margin-bottom: 10px;
      font-weight: bold;
      color: #b5c1c6;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: 5px;
        display: flex;
        align-items: flex-start;

        svg {
          margin-right: 10px;
          color: #8cabdb;
          min-width: 20px;
        }
      }
    }
  }
`;

const experiences = [
    {
      company: 'Dotpe Private Ltd, Gurugram',
      role: 'Senior Business Analyst',
      duration: 'Dec 2022 – Jul 2023',
      responsibilities: [
        'Identified, analysed, and implemented business solutions, prioritizing project tasks, and communicating with IT team members, cross-functional teams, external vendors.',
        'Ensured each project is implemented within contractual obligations, internal SOPs, and regulatory requirements.',
        'Gathered and analysed the client’s business requirements and documents before transferring to the development team and tracking the developer’s progress during each Sprint.',
        'Utilized Agile project management tools such as Jira for backlog management, sprint planning, and tracking project progress.',
        'Facilitated Agile ceremonies, including Sprint Planning, Daily Stand-ups, Sprint Review, and Sprint Retrospective, ensuring effective communication and collaboration among team members.',
        'Worked closely with Product Owners to refine and prioritize the product backlog, ensuring that features and user stories were well-defined and aligned with business goals.'
      ],
    },
    {
      company: 'Yamaha Motors Solutions Private Ltd, Noida',
      role: 'Business Analyst',
      duration: 'Jun 2022 – Dec 2022',
      responsibilities: [
        'Utilized SQL queries to perform data retrieval from SQL server and created dashboards and reports in Tableau to provide to the senior management.',
        'Managed the Software Development Life Cycle (SDLC), from initiation to closure, ensuring adherence to project timelines, scope, and budget constraints.',
        'Played a key role in implementing Agile frameworks to enhance project delivery efficiency and adaptability.',
        'Actively participated in sprint and release planning sessions, contributing to the estimation and prioritization of user stories and tasks.'
      ],
    },
    {
      company: 'One Trust Privacy Management Software, Bangalore',
      role: 'Business Analyst',
      duration: 'Dec 2021 – Jul 2022',
      responsibilities: [
        'Expertise in writing Business Requirement Documents (BRD) & functional specifications, and performing and managing detailed and complex business requirements.',
        'Conducted comprehensive analysis of business processes, identifying areas for improvement and efficiency gains.',
        'Communicated and worked closely with different stakeholders like Project Managers, Business Analysts, and Clients to effectively collect business requirements.',
        'Analysed business reports for prompt communication, follow-up, and ongoing support.',
        'Identified software vulnerabilities and enforced security practices, prioritizing high-risk areas through business impact analysis.'
      ],
    },
    {
      company: 'Accenture Solutions Private Ltd, Pune',
      role: 'Application Development Analyst',
      duration: 'Feb 2019 – Dec 2021',
      responsibilities: [
        'Knowledge of UML for creating use cases, activity diagrams, and sequence diagrams.',
        'Developed and maintained detailed project documentation, including process flow diagrams, use cases, and requirements traceability matrices.',
        'Identified, analysed, and implemented business solutions, prioritizing project tasks, and communicating with IT team members and stakeholders.',
        'Utilized Agile project management tools such as Jira for backlog management, sprint planning, and tracking project progress.',
        'Captured functional and non-functional requirements, user expectations, system behaviour, and implications that need to be considered during development.',
        'Created project management templates (Scrum Board, Sprint Report, Burndown Chart, Gantt Chart), and documents, including project plan, requirements specifications, and system requirements specifications.',
        'Used JIRA to create and assign tickets for the development team to implement enhancements or new features.'
      ],
    },
  ];

const Experience = () => (
  <ExperienceSection id="experience">
    <h2>Experience</h2>
    <ExperienceContainer>
      <div className="experience-grid">
        {experiences.map((exp, index) => (
          <motion.div
            className="experience-item"
            key={index}
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <div>
              <h3>{exp.role} at {exp.company}</h3>
              <p>{exp.duration}</p>
              <ul>
                {exp.responsibilities.map((task, idx) => (
                  <li key={idx}>
                    <FaCheckCircle />
                    {task}
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>
    </ExperienceContainer>
  </ExperienceSection>
);

export default Experience;