import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: #22223b;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
`;

const Name = styled.h1`
  font-size: 1.8em;
  margin: 0;
  color: #f2e9e4;
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;

  li {
    margin-left: 20px;
  }

  a {
    text-decoration: none;
    color: #f2e9e4;
    font-weight: 500;
    position: relative;
    padding: 5px 0;

    &:after {
      content: '';
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #c9ada7;
      left: 0;
      bottom: 0;
      transition: width 0.3s;
    }

    &:hover:after,
    &.active:after {
      width: 100%;
    }

    &.active {
      color: #c9ada7;
      font-weight: bold;
    }
  }
`;

const sections = ['home', 'profile', 'education', 'experience', 'projects', 'skills', 'achievements'];

const Header = () => {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset + window.innerHeight / 2;
      const sectionElements = sections.map((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          const offsetTop = rect.top + window.pageYOffset;
          return {
            id: section,
            offsetTop,
          };
        }
        return null;
      }).filter(Boolean);

      const currentSection = sectionElements.reduce((prev, curr) => {
        return scrollPosition >= curr.offsetTop ? curr.id : prev;
      }, 'home');

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderContainer>
      <Nav>
        <Name>Ashish Kaushik</Name>
        <NavLinks>
          {sections.map((section) => (
            <li key={section}>
              <a href={`#${section}`} className={activeSection === section ? 'active' : ''}>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </a>
            </li>
          ))}
        </NavLinks>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;