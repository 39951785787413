import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ProjectsSection = styled.section`
  padding: 60px 20px;
  background-color: #4a4e69;
  color: #f2e9e4;

  h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
  }
`;

const ProjectsContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .projects-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .project-item {
    background-color: #22223b;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 8px rgba(0,0,0,0.15);
    }

    h3 {
      margin-bottom: 10px;
      font-size: 1.5em;
      color: #c9ada7;
    }

    p {
      margin-bottom: 10px;
      color: #f2e9e4;
    }
  }
`;

const projects = [
    {
      title: 'Automated Toll Gate Payment System',
      description: 'Developed a system using NFC technology to automate toll payments.',
    },
    {
      title: 'Predictive Analytics Project',
      description: 'Built predictive models to forecast sales using Python and R.',
    },
    {
      title: 'Data Visualization Dashboard',
      description: 'Created interactive dashboards using Tableau and Power BI.',
    },
  ];  

const Projects = () => (
  <ProjectsSection id="projects">
    <h2>Projects</h2>
    <ProjectsContainer>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <motion.div
            className="project-item"
            key={index}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <h3>{project.title}</h3>
            <p>{project.description}</p>
          </motion.div>
        ))}
      </div>
    </ProjectsContainer>
  </ProjectsSection>
);

export default Projects;