import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AchievementsSection = styled.section`
  padding: 60px 20px;
  background-color: #22223b;
  color: #f2e9e4;

  h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
  }
`;

const AchievementsContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .achievements-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }

  .achievement-item {
    background-color: #ffffff; /* Changed to white */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: #212529; /* Dark text for contrast */
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    p {
      font-size: 1.2em;
    }
  }
`;

const achievements = [
  'Core member of the Business Analytics society at NUIG.',
  'Facilitator for the complete LIFT Ireland program at National University of Galway.',
  'Received “Star” of the month award for performance during a major release at Accenture.',
  'Published a paper on the project "Automated Toll Gate Payment System through NFC Technology".',
];

const Achievements = () => (
  <AchievementsSection id="achievements">
    <h2>Achievements</h2>
    <AchievementsContainer>
      <div className="achievements-grid">
        {achievements.map((achievement, index) => (
          <motion.div
            className="achievement-item"
            key={index}
            whileHover={{ scale: 1.05 }}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
          >
            <p>{achievement}</p>
          </motion.div>
        ))}
      </div>
    </AchievementsContainer>
  </AchievementsSection>
);

export default Achievements;