import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FaUniversity } from 'react-icons/fa';

const EducationSection = styled.section`
  padding: 60px 20px;
  background-color: #4a4e69; /* Matching the Projects section */
  color: #f2e9e4;

  h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
    color: #b5c1c6;
  }
`;

const EducationContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .education-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
  }

  .education-item {
    background-color: #22223b;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 6px 8px rgba(0,0,0,0.15);
    }

    h3 {
      font-size: 1.5em;
      margin-bottom: 10px;
      color: #c9ada7;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h4 {
      font-size: 1.2em;
      margin-bottom: 8px;
      color: #c9ada7;
    }

    p {
      margin-bottom: 12px;
      font-weight: bold;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        margin-bottom: 8px;
        position: relative;
        padding-left: 25px;

        &:before {
          content: '•';
          position: absolute;
          left: 0;
          color: #c9ada7;
        }
      }
    }
          p, ul, li {
      color: #f2e9e4;
    }
  }
`;

const educationData = [
    {
        institution: 'National University of Ireland, Galway',
        degree: 'MSc in Business Analytics',
        duration: 'Sep 2023 - Present',
        details: [
            'Relevant coursework: Data Visualization, Predictive Analytics, Machine Learning.',
            'Projects: Developed predictive models using Python and R.',
        ],
    },
    {
        institution: 'Jain University Bangalore',
        degree: 'B. Tech in Information Science and Engineering',
        duration: 'Sep 2014 - May 2018',
        details: [
            'Relevant coursework: Algorithms, Data Structures, Database Systems.',
            'Thesis: Implemented an automated toll gate payment system using NFC technology.',
        ],
    },
];

const Education = () => (
    <EducationSection id="education">
        <h2>Education</h2>
        <EducationContainer>
            <div className="education-grid">
                {educationData.map((edu, index) => (
                    <motion.div
                        className="education-item"
                        key={index}
                        whileHover={{ scale: 1.05 }}
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: index * 0.2 }}
                    >
                        <h3>
                            <FaUniversity />
                            {edu.institution}
                        </h3>
                        <h4>{edu.degree}</h4>
                        <p>{edu.duration}</p>
                        <ul>
                            {edu.details.map((detail, idx) => (
                                <li key={idx}>{detail}</li>
                            ))}
                        </ul>
                    </motion.div>
                ))}
            </div>
        </EducationContainer>
    </EducationSection>
);

export default Education;