
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ProfileSection = styled.section`
  padding: 60px 20px;
  background-color: #c9ada7;
  color: #22223b;

  h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
  }

  p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.2em;
    line-height: 1.6;
  }
`;

const Profile = () => (
    <ProfileSection id="profile">
        <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
        >
            Professional Profile
        </motion.h2>
        <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
        >
        Versatile IT Professional with over 5 years in software engineering and business analysis, blending technical prowess with strategic business insights. Expert in enhancing product functionality and efficiency through advanced testing frameworks and business analytics. Skilled in aligning project objectives with market demands, driving innovation, and improving customer satisfaction. Proficient in data visualization and business intelligence to inform strategic decisions. Adept at cross-functional collaboration, aiming to bridge the gap between technology solutions and business goals.
      
        </motion.p>
    </ProfileSection>
);

export default Profile;