import React, { useState } from 'react';
import styled from 'styled-components';

const SkillsSection = styled.section`
  padding: 60px 20px;
  background-color: #9a8c98;
  color: #22223b;

  h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
  }
`;

const SkillsContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  .skill-category {
    margin-bottom: 30px;

    h3 {
      font-size: 1.8em;
      margin-bottom: 10px;
      cursor: pointer;
      position: relative;

      &:after {
        content: '+';
        position: absolute;
        right: 0;
      }
    }

    .sub-skills {
      margin-left: 20px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease;

      &.expanded {
        max-height: 500px; /* Adjust as needed */
      }

      li {
        margin-bottom: 10px;
        font-size: 1.2em;
      }
    }
  }
`;

const skillData = [
  {
    category: 'Programming Languages',
    skills: [
      { name: 'Python', level: 'Advanced' },
      { name: 'R Script', level: 'Intermediate' },
      { name: 'C++', level: 'Intermediate' },
    ],
  },
  {
    category: 'Data Visualization',
    skills: [
      { name: 'Power BI', level: 'Advanced' },
      { name: 'Tableau', level: 'Advanced' },
    ],
  },
  {
    category: 'Project Management Tools',
    skills: [
      { name: 'JIRA', level: 'Advanced' },
      { name: 'Git', level: 'Advanced' },
    ],
  },
  // Add more categories as needed
];

const Skills = () => {
  const [expandedCategories, setExpandedCategories] = useState([]);

  const toggleCategory = (index) => {
    if (expandedCategories.includes(index)) {
      setExpandedCategories(expandedCategories.filter((i) => i !== index));
    } else {
      setExpandedCategories([...expandedCategories, index]);
    }
  };

  return (
    <SkillsSection id="skills">
      <h2>Skills</h2>
      <SkillsContainer>
        {skillData.map((category, index) => (
          <div className="skill-category" key={index}>
            <h3 onClick={() => toggleCategory(index)}>
              {category.category}
            </h3>
            <ul className={`sub-skills ${expandedCategories.includes(index) ? 'expanded' : ''}`}>
              {category.skills.map((skill, idx) => (
                <li key={idx}>
                  {skill.name} - {skill.level}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </SkillsContainer>
    </SkillsSection>
  );
};

export default Skills;