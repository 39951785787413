import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const FooterContainer = styled.footer`
  background-color: #1a202c;
  padding: 40px 20px;
  text-align: center;
  border-top: 1px solid #2d3748;
  color: #f0f8ff;
`;

const SocialLinks = styled.div`
  margin-top: 20px;

  a {
    margin: 0 15px;
    color: #f0f8ff;
    font-size: 1.5em;

    &:hover {
      color: #63b3ed;
    }
  }
`;

const Footer = () => (
    <FooterContainer>
        <p>© {new Date().getFullYear()} Ashish Kaushik</p>
        <p>Email: <a href="mailto:ashish48ak@gmail.com">ashish48ak@gmail.com</a> | Phone: +353 89 986 6198</p>
        <SocialLinks>
            <a href="https://www.linkedin.com/in/ashish-kaushik-ak4812" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://github.com/Ashish4812" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} />
            </a>
        </SocialLinks>
    </FooterContainer>
);

export default Footer;